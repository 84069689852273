import React from 'react'
export default function Success(){

  return (
    <div className="text-center d-flex flex-column align-items-center justify-content-center mb-5">

      <i style={{fontSize:'3rem',color:'green'}} className="las la-check-circle"></i>

    <h3 className="my-3">Το μήνημα στάλθηκε με επιτυχία</h3>

    </div>
  )
}
