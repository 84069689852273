import React, {useState,useEffect} from 'react'
import {Modal} from 'react-bootstrap'




function ModalPost({reveal,setReveal,children}) {
  const [show, setShow] = useState(false);

  const handleClose = () => {setReveal(false)};
  const handleShow = () => setShow(true);

  useEffect(() => {

    setShow(reveal)

  },[reveal]);

  return (
    <>


      <Modal centered={true} show={show} onHide={handleClose}>
        <Modal.Header style={{border:'none'}} closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {children}

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default ModalPost
